body {
  font-family: Kanit-Regular, Roboto, Helvetica, Arial, sans-serif;
  overflow-x: hidden;
}

img {
  object-fit: contain;
}

@font-face {
  font-family: 'Kanit-Regular';
  src: url('./fonts/Kanit-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Kanit-Bold';
  src: url('./fonts/Kanit-Bold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Kanit-Semibold';
  src: url('./fonts/Kanit-SemiBold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}